<script setup lang="ts">
import type { Game } from "@/types";

const { t } = useT();
const { open, close } = useNlcModals();
const { handleOpenGame } = useOpenGame(open);
const { activeGameMode } = useSwitchMode();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();

const props = defineProps<{
	game: Game & { skeleton?: boolean };
	hideTitle?: boolean;
	hideFavoriteBtn?: boolean;
}>();
const emit = defineEmits<{ (event: "toggleFavorite", game: Game): void; (event: "close"): void }>();

const { data: appInit } = useAppInitData();
const prepareImgUrl = useImage();

const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const isGameFavorite = computed(() => appInit.value?.favoriteGames?.includes(props.game.id));
const alt = computed(() => removeHtmlTags(props.game.title || ""));
const exclusiveLock = computed(() => props.game.isExclusive && activeGameMode.value === "coins");

const handleToggleToFavorite = () => {
	emit("toggleFavorite", { ...props.game, isFavorite: isGameFavorite.value });
};

const handleOverlayClick = () => {
	if (exclusiveLock.value) {
		loginGuard({
			success: () => {
				open("LazyOModalGameExclusive", { gameId: props.game.id });
			},
			fail: () => {
				open("LazyOModalSignup", { location: "button_right" });
			}
		});

		return;
	}
	if (!props.game.isOnlyForApp) {
		if (!props.game.gamePlayInfo) {
			close("LazyOModalRaceGames");
		}
		emit("close");
		handleOpenGame(props.game.slug);
	}
};
</script>

<template>
	<div :class="['card-wrapper', { 'has-skeleton': props.game.skeleton }]">
		<AAnimationSkeleton v-if="props.game.skeleton" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>
		<ACard
			v-else
			:imgAttrs="{
				width: 158,
				height: 179,
				src: `${baseImageUrl}${game?.logo || game?.img}`,
				alt,
				loading: 'lazy',
				format: 'avif'
			}"
			width="auto"
			height="auto"
			stretch="113%"
			:data-tid="`gcard-${game.id}`"
			@click.stop="handleOverlayClick"
		>
			<div v-if="(props.game.isNew && !props.game.isOnlyForApp) || props.game.isExclusive" class="badge-wrap">
				<ABadge v-if="props.game.isExclusive && !isGuest" autosize variant="info" background="var(--custom-7)">
					<MPrizeFund variant="entries" icon="20/entries" :icon-size="13">
						<AText class="color-custom-9" variant="ternopil" :modifiers="['bold', 'uppercase']">
							{{ t("SC exclusive") }}
						</AText>
					</MPrizeFund>
				</ABadge>
				<ABadge
					v-if="props.game.isNew && !props.game.isOnlyForApp"
					variant="info"
					background="var(--custom-13)"
					autosize
				>
					<AText variant="ternopil" :modifiers="['bold', 'uppercase']">{{ t("New") }}</AText>
				</ABadge>
			</div>
			<div v-if="exclusiveLock" class="lock-exclusive-wrapper">
				<NuxtImg
					class="lock-exclusive-icon"
					src="/nuxt-img/card-games/lock-exclusive.png"
					loading="lazy"
					format="webp"
					width="38"
					height="53"
					alt="exclusive game lock"
				/>
			</div>
			<div v-else-if="game?.isOnlyForApp" class="lock-overlay">
				<AOverlay :modifiers="['blur', 'flex-center']" bgColor="var(--custom-rgb-12)">
					<NuxtIcon name="40/locked" filled />
				</AOverlay>
			</div>
			<AAnimationFadeIn v-else>
				<AOverlay>
					<div class="game-content">
						<div class="center-block">
							<AButton variant="ghost" :data-tid="`gplay-${game.id}`" :modifiers="['circle']">
								<NuxtIcon name="48/play" filled />
							</AButton>
						</div>
					</div>
				</AOverlay>
			</AAnimationFadeIn>
			<div v-if="!appInit?.isGuest && !hideFavoriteBtn" class="top-block">
				<AButton
					variant="toolbar"
					:modifiers="isGameFavorite ? ['active'] : undefined"
					:data-tid="`fav-${game.id}`"
					@click.stop="handleToggleToFavorite"
				>
					<NuxtIcon name="16/fav" filled />
				</AButton>
			</div>
		</ACard>
		<AText v-if="!hideTitle" class="game-name" as="p">{{ game.title }}</AText>
	</div>
</template>

<style scoped lang="scss">
.has-skeleton {
	position: relative;
	--a-skeleton-background-color: var(--cuba);
	padding-top: 70%;
}
.skeleton-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.lock-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 56px;
	height: 56px;
	position: absolute;
	border-radius: 16px;
	overflow: hidden;
	font-size: 40px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.top-block {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	bottom: 4px;
	right: 4px;
	z-index: 10;

	button {
		--a-button-toolbar-background: var(--custom-9);
		--a-button-toolbar-background-active: var(--custom-9);
		padding: gutter(0.5);
	}
}

.game-content {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.center-block {
		position: absolute;
		display: flex;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		right: 0;

		button.ghost {
			transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: 44px;
			padding: guter(0.5) gutter(1);
			box-shadow: none;

			&:deep(.nuxt-icon) {
				transition: all 0.3s ease-in;
			}
		}
	}
}

.card-wrapper {
	display: inline-flex;
	flex-direction: column;

	.game-name {
		padding-top: gutter(1);
	}

	@media (hover: hover) {
		&:hover .card > div {
			opacity: 1;
		}
	}

	.card:before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: v-bind(imagePattern);
		position: absolute;
		z-index: -1;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}
}

.badge-wrap {
	position: absolute;
	top: gutter(1);
	left: gutter(1);
	z-index: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: gutter(0.5);

	@include media-breakpoint-down(lg) {
		flex-wrap: wrap;
	}
}
.lock-exclusive {
	&-wrapper {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--gradient-30);
		transition: all 2s ease-in-out;
	}
	&-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>
